import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";

import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/seven-css.css';
import seven1 from './assets/images/seven1.png'
import seven2 from './assets/images/seven2.png'
import seven3 from './assets/images/seven3.png'
import seven4 from './assets/images/seven4.png'
import seven5 from './assets/images/seven5.png'
import icon1 from './assets/images/grid-8.png';
import icon2 from './assets/images/brush.png';
import icon3 from './assets/images/key.png';
import icon4 from './assets/images/Frame 1285.png'
import { fetchUserData } from './utils';

function Seventh() { // Component names should be capitalized
  const [selectedOption, setSelectedOption] = useState(1);
  const public_stripe_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  // Function to handle image click
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    fetchUserData(() => { }, setUserEmail, () => { }, () => { });
  }, []);

  const handleSubscription = async () => {
    const stripePromise = await loadStripe(public_stripe_key);
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-stripe-session`, {
      method: "POST",
      headers: { "Content-Type": "Application/JSON" },
      body: JSON.stringify({ selectedOption, userEmail })
    });

    if (response.status === 409) {
      const data = await response.json();
      if (data && data.redirectUrl) {
        window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
      }
    } else {
      const session = await response.json();
      stripePromise.redirectToCheckout({
        sessionId: session.id,
      });
    }
  };

  return (
    <div className="App app-seventh">
      <div className="container container-seventh-width" > {/* Use the same className as your App component for consistency */}
        <header className="App-header-seventh">

              <div className='row-img'>
                <img src={seven5} alt="Centered" className='thr-img-seventh margin-right-seven' style={{ rotate: '-8deg' }} />
                <img src={seven4} alt="Centered" className='sec-img-seventh margin-right-seven' style={{ rotate: '-3deg', marginBottom: '5px' }} />
                <img src={seven1} alt="Centered" className='front-img-seventh' />
                <img src={seven2} alt="Centered" className='sec-img-seventh margin-left-seven' style={{ rotate: '3deg', marginBottom: '5px' }} />
                <img src={seven3} alt="Centered" className='thr-img-seventh margin-left-seven' style={{ rotate: '8deg' }} />
              </div>


          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <div style={{ margin: '0px', padding: '0px' }}>
              <span className='seventh-text'>Discover yourself in new</span>
              <div style={{ margin: '0px', padding: '0px' }}>
                <span className='seventh-text'>styles with </span>
                <span className='seventh-text-gradient'>PRO</span>
              </div>
            </div>
            <div className='div-rows' >
              <div className='row-image-text'>
                <img src={icon1} alt="icon 1" />
                <div style={{ gap: '5px' }}>
                  <span className='seventh-text-small-gradient'> Unlimited</span>
                  <span className='seventh-text-small'> generations</span>
                </div>
              </div>

              <div className='row-image-text'>
                <img src={icon2} alt="icon 2" />
                <div style={{ gap: '5px' }}>
                  <span className='seventh-text-small'>&gt;100 </span>
                  <span className='seventh-text-small-gradient'>unique</span>
                  <span className='seventh-text-small'> styles</span>
                </div>
              </div>

              <div className='row-image-text'>
                <img src={icon3} alt="icon 3" />
                <div style={{ gap: '5px' }}>
                  <span className='seventh-text-small-gradient'>Full</span>
                  <span className='seventh-text-small'> access to community</span>
                </div>
              </div>
            </div>
          </div>

        </header>

        <div className="bottom-button-seventh">
          <div className='options-wraps'>
            <div className={`option-box ${selectedOption === 0 ? 'payment-with-border' : 'payment-no-border'}`}
              onClick={() => handleOptionClick(0)} style={{ width: '100%' }}>
              <div className='vertical-stack'>
                <span className='grey-option-text'>Pay Every Week</span>
                <span className='white-price-text'>$4.99 per week</span>
              </div>
            </div>

            <div className={`option-box ${selectedOption === 1 ? 'payment-with-border' : 'payment-no-border'}`}
              onClick={() => handleOptionClick(1)} style={{ width: '100%' }}>
              <div class="option-texts vertical-stack">
                <span class="grey-option-text">Pay Annually</span>
                <span class="white-price-text">$39.99 per year</span>
              </div>
              <img src={icon4} alt="Option" class="option" />
            </div>
          </div>
          <button className="App-button" style={{ width: '100%' }} onClick={() => handleSubscription()}>Continue</button>
          {/* <button className="App-button" style={{ width: '100%' }} >Continue</button> */}

          <div style={{ display: 'flex', width: "80%", justifyContent: 'space-between' }}>
            <a href="https://docs.google.com/document/d/18G8rBptbbJePbcR1MDLOX-rWkjNNw2Hk5kYyLqPj4to/edit#heading=h.m3wd8wirucmj" className='seventh-anchors' target="_blank" rel="noopener noreferrer">Terms & Privacy</a>
            <a href="https://docs.google.com/document/d/18G8rBptbbJePbcR1MDLOX-rWkjNNw2Hk5kYyLqPj4to/edit#heading=h.m3wd8wirucmj" className='seventh-anchors' target="_blank" rel="noopener noreferrer">Restore Purchases</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Seventh;