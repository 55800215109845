import React from 'react';
import './css/App.css';
import './css/history.css';

function HistoryItem({ image1, image2, image3, image4, onClick }) {
    const images = [image1, image2, image3, image4];

    return (
        <div className="history-item-deck" onClick={onClick}>
            {images.map((url, index) =>
                url ? <img key={index} src={url} alt={`Image ${index + 1}`} /> : null
            )}
        </div>
    );
}

export default HistoryItem;