import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import the Link component
import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/six-css.css';
import ResultItem from './result-item';
import ImageGallery from 'react-image-gallery';
import iconCloseHistory from './assets/images/close-sign-up-desktop.png';

function Sixth() { // Component names should be capitalized
  const location = useLocation();
  const imageUrls = location.state?.imageUrls;

  const navigate = useNavigate(); // Hook to get the navigate function

  const goToSeventhPage = () => {
    localStorage.clear();
    navigate('/seventh'); // Use navigate with the path to move to the seventh page
  };

  const [showGallery, setShowGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const openGallery = (item) => {
    const images = item
      .filter(url => url) // Filter out any null or undefined URLs
      .map(url => ({ original: url })); // Map to the format expected by ImageGallery
    setGalleryImages(images);
    setShowGallery(true);
  };

  useEffect(() => {
    // Call the backend endpoint to clear temporary files
    fetch(`${process.env.REACT_APP_SERVER_URL}/clear-all`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ folderName: localStorage.getItem('uniqueFolderName') }),
      body: JSON.stringify({
        uniqueFolderName: localStorage.getItem('uniqueFolderName'),
        uploadedFilePath: localStorage.getItem('uploadedFilePath')
      })
    })
      .then(response => {
        if (!response.ok) {
          // If the response is not 2xx, it will be considered an error
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          // console.log('Temporary files cleared successfully');
        }
      })
      .catch(error => {
        console.error('Error clearing temporary files:', error);
      })
      .finally(() => {
        // Remove items from localStorage regardless of the fetch outcome
        localStorage.removeItem('uploadedFilePath');
      });

    localStorage.removeItem('selectedGender');
    // localStorage.removeItem('selectedStyleIndex');

  }, []);

  return (
    <div className="App"> {/* Use the same className as your App component for consistency */}
      <div className="container"> {/* Use the same className as your App component for consistency */}

        <header className="App-header-six">
          {/* <img className="image-sixth" src={data.image} alt="result" /> */}
          <div onClick={() => openGallery(imageUrls)}>
            <ResultItem
              image1={imageUrls[0]}
              image2={imageUrls[1]}
              image3={imageUrls[2]}
              image4={imageUrls[3]}
            />
          </div>

        </header>
        <div id="saveInstructions" className='image-save-text'>
          <div>
            <span className='save-text'>To </span>
            <span className='span-first-gradient' style={{ fontStyle: 'italic' }}>save</span>
            <span className='save-text'> the image to your Photos:</span>
          </div>
          <div className='arrange-start'>
            <span className='save-text'>1. Tap and hold on the image.</span>
            <span className='save-text'>2. Select 'Add to Photos'.</span>
          </div>
        </div>
        <div className="bottom-button">
          <button className="App-button" onClick={goToSeventhPage}>Next</button>
        </div>
      </div>
      {showGallery && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            background: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => setShowGallery(false)} // Close gallery when overlay is clicked
        >
          <div onClick={(e) => e.stopPropagation()}> {/* Prevent click from closing the gallery */}
            <ImageGallery
              items={galleryImages}
              showPlayButton={false} // Hides the play button
              showFullscreenButton={false} // Hides the fullscreen button
            />
            <img src={iconCloseHistory} alt="close"

              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1001 // Ensure the button is above the gallery images
              }}
              onClick={() => setShowGallery(false)}
            />

          </div>
        </div>
      )}

    </div>
  );
}

export default Sixth;