import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import heic2any from 'heic2any';
import './css/three-css.css';
import './css/App.css'; // Assuming you want to use the same CSS as your App component

import okImage from './assets/images/Frame 1642.png'; // Path to your new image 
import image1 from './assets/images/Frame 1643.png';
import image2 from './assets/images/Frame 1644.png';
import notOkImage from './assets/images/Frame 16421.png'; // Path to your new image 
import image3 from './assets/images/Frame 16431.png';
import image4 from './assets/images/Frame 16441.png';

import image11 from './assets/images/image758.png';
import image21 from './assets/images/image759.png';
import image31 from './assets/images/image760.png';
import image41 from './assets/images/image761.png';
import iconBack from './assets/images/icon-back.png';
import { fetchUserData } from './utils';

function Third() { // Component names should be capitalized
  const desktopWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
  const [userStatus, setUserStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  useEffect(() => {
    fetchUserData(() => { }, () => { }, setUserStatus, setUserId);
    setIsLoading1(false);
  }, []);

  const [userHistory, setUserHistory] = useState([]);

  useEffect(() => {
    const fetchUserHistory = async () => {
      if (userId) { // Only proceed if userId is not null
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/history/${userId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          // console.log('history data from db:', data);
          setUserHistory(data);
        } catch (error) {
          console.error("Failed to fetch user history:", error);
        }
      }
    };

    fetchUserHistory();
    setIsLoading2(false);

  }, [userId]);

  const location = useLocation();
  const fromFifth = location.state?.fromFifth || false;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < desktopWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setIsUploading(true); // Disable the button and change the caption

    const fileExtension = file.name.split('.').pop().toLowerCase();
    // console.log("Extracted extension: " + fileExtension);
    // Check if the file extension is HEIC or HEIF
    if (fileExtension === 'heic' || fileExtension === 'heif') {
      // console.log('File is HEIC or HEIF, converting to JPEG...');
      convertHEIFtoJPEG(file);
    } else {
      // console.log('File is not HEIC or HEIF, uploading original file...');
      uploadFile(file);
    }
  };

  const convertHEIFtoJPEG = async (file) => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.8 // Adjust the quality as needed
      });
      // console.log('Conversion successful, uploading...');
      // Create a new file object from the converted blob with a .jpg extension
      const jpegFile = new File([convertedBlob], 'upload.jpg', {
        type: 'image/jpeg',
        lastModified: new Date().getTime()
      });
      // console.log(`Uploading file: ${jpegFile.name}`);
      uploadFile(jpegFile);
    } catch (error) {
      console.error('Error converting image:', error);
    }
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    fetch(`${process.env.REACT_APP_SERVER_URL}/img-upload`, { // Replace with your actual endpoint
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.filePath) {
          // console.log('File uploaded successfully:', data.filePath);
          localStorage.setItem('uploadedFilePath', data.filePath);

          // localStorage.setItem('uploadedFilePath', 'https://headshotai.app/data/source/1714500928/300424181528.png');
          navigate('/forth');
        } else {
          throw new Error('File path not provided in response.');
        }
      })
      .catch(error => {
        console.error('Upload error:', error);
        setIsUploading(false); // Re-enable the button if upload fails
      });
  };

  const handleUploadClick = () => {
    if (!isLoading1 && !isLoading2) {
      if (userStatus !== 'active' && userHistory.length >= 1) {
        navigate('/seventh');
      } else {
        hiddenFileInput.current.click();
      }
    }
  };

  return (
    <div className="App">
      <div className="container"> {/* Use the same className as your App component for consistency */}

        <header className="App-header-third" >
          <div className='span-box' style={{ gap: '20px' }}>
            <Link to="/logged-in" style={{ "width": "auto", height: '100%', alignContent: 'center' }}>
              <div className='top-icons'><img src={iconBack} alt="back icon" /></div>
            </Link>
            {fromFifth ? (
              <span className='third-font' style={{ textAlign: 'start' }}>
                Something went wrong...<br /> Please try again
              </span>
            ) : (
              <span className='third-font'>Upload your photo</span>
            )}
          </div>

          {isMobile ? (
            <>
              <div className='info-box'>
                <div className='span-wrapper'>
                  <div className='third-font-small'>
                    <span>Make sure to upload a photo that</span>
                    <span>fully covers your face and hair</span>
                  </div>
                  <img src={okImage} className='image-fixed' alt="Ok" />
                </div>
                <div className='image-wrapper'>
                  <img src={image1} alt="1" />
                  <img src={image2} alt="2" />
                </div>
              </div>

              <div className='info-box'>
                <div className='span-wrapper'>
                  <div className='third-font-small'>
                    <span>Try to avoid photos that are cut off</span>
                    <span>or have several faces in them.</span>
                  </div>
                  <img src={notOkImage} className='image-fixed' alt="Not Ok" />
                </div>
                <div className='image-wrapper'>
                  <img src={image3} alt="3" />
                  <img src={image4} alt="4" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='info-box' style={{ display: 'flex', gap: '20px', width: '799px' }}>

                <div className='image-wrapper'>
                  <img src={image11} alt="1" style={{ marginTop: '0px', borderRadius: '16px' }} />
                  <img src={image21} alt="2" style={{ marginTop: '0px', borderRadius: '16px' }} />
                </div>

                <div className='span-wrapper' style={{ alignItems: 'flex-start', width: '416px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <div className='third-font-small'>
                      <span>Make sure to upload a photo</span>
                      <span>that fully covers your face</span>
                      <span>and hair</span>
                    </div>
                    <img src={okImage} className='image-fixed' alt="Ok" />
                  </div>
                </div>
              </div>

              <div className='info-box' style={{ display: 'flex', gap: '20px', width: '799px' }}>

                <div className='image-wrapper' style={{ alignItems: 'flex-start', width: '313px' }}>
                  <img src={image31} alt="3" style={{ marginTop: '0px', borderRadius: '16px' }} />
                  <img src={image41} alt="4" style={{ marginTop: '0px', borderRadius: '16px' }} />
                </div>

                <div className='span-wrapper' style={{ alignItems: 'flex-start', width: '416px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <div className='third-font-small'>
                      <span>Try to avoid photos that</span>
                      <span>are cut off or have several</span>
                      <span>faces in them.</span>
                    </div>
                    <img src={notOkImage} className='image-fixed' alt="Not Ok" />
                  </div>
                </div>
              </div>
            </>
          )}


        </header>
        <div className="bottom-button">
          <button
            className={isUploading ? 'button-disabled' : 'App-button'}
            onClick={handleUploadClick}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            disabled={isUploading}
          />
          {isUploading && (
            <p className="saving">Uploading <span>.</span><span>.</span><span>.</span></p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Third;