import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function AppleRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        async function retrieveAppleSessionData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get-apple-session`, {
                    credentials: 'include' // Required for cookies to be sent with the request
                });
                if (response.ok) {
                    const data = await response.json();
                    // Use the code and state from the session
                    // console.log('ourToken', data.ourToken);
                    sessionStorage.setItem('session_token', data.ourToken);
                    navigate('/logged-in');
                } else {
                    console.error('Failed to retrieve session data');
                    navigate('/login', { state: { message: 'Token not present' } });
                }
            } catch (error) {
                console.error('Error retrieving session data:', error);
                navigate('/login', { state: { message: 'Error retrieving session data' } });
            }
        }

        retrieveAppleSessionData();
    }, []);

    return (
        <div>
            <p></p>
        </div>
    );
}

export default AppleRedirect;