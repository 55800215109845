import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the Link component
import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/four-css.css';
import { fetchUserData } from './utils';


function Forth() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    fetchUserData(() => { }, () => { }, () => { }, setUserId);
  }, []);

  const handleButtonClick = (index) => {
    setIsButtonDisabled(true); // Disable the button

    // Construct the JSON payload
    const payload = {
      url: localStorage.getItem('uploadedFilePath'),
      gender: localStorage.getItem('selectedGender'),
      style: localStorage.getItem('selectedImage'),
      // promptId: localStorage.getItem('selectedStyleIndex'),
    };

    // Send the POST request to the server
    fetch(`${process.env.REACT_APP_SERVER_URL}/generate-prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Navigate to the fifth page after successful POST request
        navigate('/fifth', { state: { task_id: data.task_id } });
      })
      .catch(error => {
        console.error('Error:', error);
        setIsButtonDisabled(false); // Re-enable the button if there's an error
      });
  };

  return (
    <div className="App">
      <div className="container">
        <header className="App-header-four">
          <img className="image-forth" src={localStorage.getItem('uploadedFilePath')} alt="Onboarding" />
        </header>
        <div className="bottom-button">
          <button className="App-button" onClick={handleButtonClick} disabled={isButtonDisabled}>
            Try it now
          </button>
        </div>
      </div>
    </div>
  );
}

export default Forth;