import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function OAuthRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    // console.log('Code found: ', code);
    if (code) {
      // Send the authorization code to your backend
      sendCodeToBackend(code);
    } else {
      // console.log('No authorization code found, navigating to login');
      navigate('/login', { state: { message: 'No authorization code found' } });
    }
  }, [navigate]);

  // Define the function to send the code to your backend
  const sendCodeToBackend = async (code) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      const data = await response.json();
      // console.log('JWT token from backend', data);
      if (data.ourToken) {
        // Save the id_token and/or access_token as needed
        // console.log('OAuthRedirect data.ourToken for sessionStorage.setItem : ', data.ourToken);
        sessionStorage.setItem('session_token', data.ourToken);
        navigate('/logged-in');
      } else {
        navigate('/login', { state: { message: 'Token not present' } });
      }
    } catch (error) {
      console.error('Error sending authorization code to backend:', error);
      navigate('/login', { state: { message: 'Failed to exchange authorization code' } });
    }
  };
  
  return (
    <div>
      <p></p>
    </div>
  );
}

export default OAuthRedirect;