import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import the Link component
import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/five-css.css';
import './css/three-css.css';
import { fetchUserData } from './utils';
import LoadingItem from './loading-item';
import okImage1 from './assets/images/Frame 1642.png';

function Fifth() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [discordImageUrl, setDiscordImageUrl] = useState(null);
  const desktopWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < desktopWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [desktopWidth]);

  useEffect(() => {
    fetchUserData(() => { }, () => { }, () => { }, setUserId);
  }, []);

  const createUniqueFolderName = () => {

    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 90) + 10; // Generates a random number between 10 and 99
    const uniqueIdentifier = `${timestamp}${randomNumber}`;

    // const folderName = `C:\\Users\\Sasha\\Downloads\\${uniqueIdentifier}`;
    const folderName = `/var/www/www-root/data/www/headshotai.app/data/source/${uniqueIdentifier}`;

    localStorage.setItem('uniqueFolderName', folderName); // Save the unique folder name to local storage
    return folderName;
  };

  useEffect(() => {
    // Ensure that taskApiUrl is available
    if (!location.state || !location.state.task_id) {
      console.error('Task ID is missing');
      return;
    }
    // console.log("Location " + JSON.stringify(location.state));

    // Function to check the API response
    const pollApi = () => {
      fetch(`${process.env.REACT_APP_SERVER_URL}/check-stat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ task_id: location.state.task_id }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Convert the response to JSON
        })
        .then(data => {
          // Update the "done" value from the API response
          console.log("data stat RESPONSE" + JSON.stringify(data));

          // Check if the status is "finished" and navigate if it is
          if (data.status === 'retry' || data.status === 'failed') {
            // Navigate back to the /forth route
            navigate('/third', { state: { fromFifth: true } });
          } else if (data.status === 'finished') {
            console.log("URL RESPONSE" + JSON.stringify(data));
            // Ensure that task_result and discord_image_url exist before trying to access them
            if (data.task_result && data.task_result.discord_image_url) {
              const discUrl = data.task_result.discord_image_url;
              console.log("URL Discord" + JSON.stringify(discUrl));

              clearInterval(intervalId); // Stop polling
              setDiscordImageUrl(discUrl);
              handleSplitImage(discUrl);
            } else {
              console.error('Discord image URL is missing in the task result.');
            }
          }
        })
        .catch(error => {
          console.error('API check error:', error);
        });
    };

    const handleSplitImage = (discordImageUrl) => {
      const outputFolder = createUniqueFolderName();
      fetch(`${process.env.REACT_APP_SERVER_URL}/split-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inputDiscImage: discordImageUrl,
          inputImage: localStorage.getItem('uploadedFilePath'),
          outputDir: outputFolder,
          userId: userId
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(result => {
          if (result.imageUrls && result.imageUrls.length > 0) {
            navigate('/sixth', { state: { imageUrls: result.imageUrls } });
          } else {
            navigate('/third', { state: { fromFifth: true } });
          }
        })
        .catch(error => {
          console.error('Error calling split-image endpoint:', error);
        });
    };

    // Set up an interval to poll the API every second
    const intervalId = setInterval(pollApi, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [location.state, navigate, userId]); // Dependencies for useEffect

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 180) {
          clearInterval(progressInterval);
          checkFolderReady(userId, discordImageUrl);
          return prev;
        }
        return prev + 1;
      });
    }, 1000);

    function checkFolderReady(userId, discordImageUrl) {
      if (userId && discordImageUrl) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/check-folder?folder=${encodeURIComponent(localStorage.getItem('uniqueFolderName') + '-repl')}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(result => {
            if (result.imageUrls && result.imageUrls.length > 0) {
              navigate('/sixth', { state: { imageUrls: result.imageUrls } });
            } else {
              navigate('/third', { state: { fromFifth: true } });
            }
          })
          .catch(error => {
            console.error('Error for check-folder endpoint:', error);
          });
      }
    }

    return () => clearInterval(progressInterval);
  }, [userId, discordImageUrl, navigate]);

  return (
    <div className="App">
      <header className="App-header-fifth">
        {progress < 60 ? (
          <>
            <div className='progress-wrapper'>
              <span className='progress-text-hidden'></span>
              <span className='progress-number'>
                {progress} {isMobile ? (progress === 1 ? 'second' : 'seconds') : ''}
              </span>
              <span className='progress-text'>
                {isMobile ? '' : (progress === 1 ? 'second' : 'seconds')}
              </span>
            </div>
            <span class='generating'>Generating your AI Headshot</span>
            <span className='it-takes-text'>It takes ~60 seconds to create{isMobile ? <br /> : ' '}your AI photo</span>

            <div className="loading-item-deck" style={{ gap: progress >= 50 ? '0px' : '10px' }}>
              <LoadingItem index={progress >= 10 ? 0 : undefined} ticked={progress >= 20} combined={progress >= 50} />
              <LoadingItem index={progress >= 20 ? 1 : undefined} ticked={progress >= 30} combined={progress >= 50} />
              <LoadingItem index={progress >= 30 ? 2 : undefined} ticked={progress >= 40} combined={progress >= 50} />
              <LoadingItem index={progress >= 40 ? 3 : undefined} ticked={progress >= 50} combined={progress >= 50} />
              {progress >= 50 && <img src={okImage1} alt="Ticked" className="ticked-image" />}
            </div>
          </>
        ) : (
          <>
            {progress < 180 ? (
              <div className='finalizing-wrapper'>
                <p className="finalizing">Finalizing<span>.</span><span>.</span><span>.</span></p>
                <span class='generating'>Generating your AI Headshot</span>
                <span className='it-takes-text'>A few seconds and your AI{isMobile ? <br /> : ' '}photo is ready</span>
              </div>
            ) : null}
          </>
        )}

      </header>
    </div>
  );
}

export default Fifth;