import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './css/App.css';
import './css/account.css';

import iconUser from './assets/images/user.png';
import iconBack from './assets/images/icon-back.png';
import iconChevron from './assets/images/chevron-right.png';
import { fetchUserData } from './utils';

function Account() {
    const [userPicture, setUserPicture] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        fetchUserData(setUserPicture, setUserEmail, () => { }, () => { });
    }, []);

    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('session_token');
        navigate('/');
    };

    return (
        <div className="App" >
            <div className='top-line-account'>
                <div className='top-line-account-inner'>
                    <Link to="/logged-in" style={{ "width": "auto" }}>
                        <div className='top-icons'><img src={iconBack} alt="back icon" /></div>
                    </Link>
                </div>
            </div>
            <div className='profile-wrapper'>
                {userPicture ? ( 

                    <img src={userPicture} className='account-img' alt="User" />


                ) : ( 
                <div className='profile-item profile-circle' >
                    <img src={iconUser} alt="User Icon" style={{ width: "16px", height: "16px" }} />
                </div>
                )}
                <p id='user-email-from-server' style={{ height: userEmail ? 'auto' : '20px' }}>
                    {userEmail}
                </p>

                <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", width: "100%" }}>
                    <div className='account-p'>
                        <p style={{ fontWeight: 500 }}>Account</p>
                    </div>
                    <div className='account-info-links'>
                        <a href="https://docs.google.com/document/d/18G8rBptbbJePbcR1MDLOX-rWkjNNw2Hk5kYyLqPj4to/edit#heading=h.m3wd8wirucmj" target="_blank" rel="noreferrer" className='profile-links' style={{ display: 'block' }}>
                            <div className='profile-item' >
                                Privacy policy
                                <img src={iconChevron} alt="arrow right" />
                            </div>
                        </a>
                        <a href="https://docs.google.com/document/d/18G8rBptbbJePbcR1MDLOX-rWkjNNw2Hk5kYyLqPj4to/edit#heading=h.m3wd8wirucmj" target="_blank" rel="noreferrer" className='profile-links' style={{ display: 'block' }}>
                            <div className='profile-item' >
                                Terms of use
                                <img src={iconChevron} alt="arrow right" />
                            </div>
                        </a>
                    </div>
                </div>


            </div>
            <div className="bottom-button">
                <button onClick={handleLogout} className="App-button-account">Log out</button>
            </div>
        </div>
    );
}

export default Account;