import React from 'react';
import './css/nine-css.css';

function Ninth() { // Component names should be capitalized

  return (
    <div className="custom-page-background">
      <div className="container"> {/* Use the same className as your App component for consistency */}
        <div><span className='success-text'>Success</span></div>
        <span className='gray-text'>How to activate your subscription</span>
        <div className='div-text' style={{ marginBottom: '12px', marginTop: '25px' }}>
        <span className='gray-text'>Step 1. </span>
        <span className='white-thick-text'>Download the app</span>
        </div>
        <a href="https://apps.apple.com/ru/app/ai-headshot-generator-truepic/id1672750333?l=en-GB" target="_blank" rel="noreferrer">
        <button className="App-button">Download</button>
        </a>
        <div className='div-text' style={{ marginBottom: '12px', marginTop: '25px' }}>
        <span className='gray-text'>Step 2. </span>
        <span className='white-thick-text'>Sign-in</span>
        </div>
        
        <hr/>
        <div className='div-white-text-bottom' >
        <span className='white-text' >Log in via "Sign in with Apple" in the app and explore unlimited possibilities</span>
        </div>
        <div className='div-gray-text-bottom' >
        <span className='gray-text-bottom'>*Please ensure that you use the same Apple ID and email for both the website and the app.</span>

        </div>
        
    </div>
    </div>
  );
}

export default Ninth;