import React from 'react';
import './css/App.css';
import './css/five-css.css';
import imageLoad1 from './assets/images/load01.png';
import imageLoad2 from './assets/images/load02.png';
import imageLoad3 from './assets/images/load03.png';
import imageLoad4 from './assets/images/load04.png';
import okImage from './assets/images/Frame 1642.png';


function LoadingItem({ index, ticked, combined}) {
    const images = [imageLoad1, imageLoad2, imageLoad3, imageLoad4];

    let combinedClass = '';
    if (combined) {
        if (window.innerWidth <= 768) { // Mobile devices
            if (index === 0) {
                combinedClass = 'combined-first';
            } else if (index === 1) {
                combinedClass = 'combined-second';
            } else if (index === 2) {
                combinedClass = 'combined-third';
            } else if (index === 3) {
                combinedClass = 'combined-last';
            }
        } else { // Desktop devices
            if (index === 0) {
                combinedClass = 'combined-first';
            } else if (index === 1 || index === 2) {
                combinedClass = 'combined-middle';
            } else if (index === 3) {
                combinedClass = 'combined-last';
            }
        }
    }

    return (
        <div className={`loading-item ${combinedClass}`}>
            {index !== undefined && index >= 0 && index < images.length && (
                <img className="loading-item-image" src={images[index]} alt={`Loading ${index}`} />
            )}
            {ticked && !combined && (
                <img src={okImage} alt="Ticked" className="ticked-image" />
            )}
        </div>
    );
}

export default LoadingItem;