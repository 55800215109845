import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import './css/App.css';
import './css/login.css';
import './css/account.css';
import './css/history.css';

import loggedSlider from './assets/images/slider-horizontal-gray.png';
import loggedRefresh from './assets/images/refresh-left-square-active.png';
import iconBack from './assets/images/icon-back.png';
import iconArchive from './assets/images/gallery-remove.png';
import HistoryItem from './history-item';
import iconCloseHistory from './assets/images/close-sign-up-desktop.png';
import { fetchUserData } from './utils';

function History() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    
    const [showGallery, setShowGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);

    const openGallery = (item) => {
        const images = [item.image4, item.image3, item.image2, item.image1]
            .filter(url => url) // Filter out any null or undefined URLs
            .map(url => ({ original: url })); // Map to the format expected by ImageGallery
        setGalleryImages(images);
        setShowGallery(true);
    };

    const [userId, setUserId] = useState(null);
    
    useEffect(() => {
        fetchUserData(() => { }, () => { }, () => { }, setUserId);
    }, []);

    const [userHistory, setUserHistory] = useState([]);

    useEffect(() => {
        const fetchUserHistory = async () => {
            if (userId) { // Only proceed if userId is not null
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/history/${userId}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    // console.log('history data from db:', data);
                    setUserHistory(data);
                } catch (error) {
                    console.error("Failed to fetch user history:", error);
                }
            }
        };
    
        fetchUserHistory();
    
    }, [userId]);


    return (
        <div className="App" style={{ justifyContent: 'flex-start', height: '100%' }}>

            <div className='div-top-bottom-history'>

                <div style={{ width: "100%", display: "flex", justifyContent: 'flex-start', marginTop: "20px" }}>
                    {/* <Link to="/logged-in" style={{ "width": "auto" }}> */}
                    <Link to="/logged-in" style={{ "width": "auto" }}>
                        <div className='top-icons'>
                            <img src={iconBack} alt="back icon" style={{ opacity: '0.65' }} />
                        </div>
                    </Link>


                </div>

            </div>

            <div className='logged-container' style={{ padding: '0px' }} >
                <div className='history-head'>
                    <p>History</p>
                </div>
                {userHistory === null ? (
                    <div style={{ height: "70vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <p>Loading...</p>
                    </div>
                ) : userHistory.length === 0 ? (
                    <div style={{ height: "70vh", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "10px" }}>

                        <img src={iconArchive} alt="Archive Icon" />

                        <div>
                            <p className='history-archive-text history-archive-text-small history-text-width'>
                                <span style={{ opacity: 0.65 }}>
                                    You still haven’t created any AI headshots yet. Generate your first AI portrait </span>
                                <Link to="/logged-in" style={{ color: 'white', textUnderlineOffset: '3px' }}>here</Link>
                            </p>
                        </div>
                    </div>

                ) : (

                    <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                        {userHistory.reduce((rows, item, index) => {
                            // Create a new row after every second item
                            const itemsPerRow = isMobile ? 2 : 4;

                            // Create a new row after every 'itemsPerRow' items
                            if (index % itemsPerRow === 0) {
                                rows.push([]);
                            }
                            // Push the current item into the last row
                            rows[rows.length - 1].push(
                                <div className="history-item-container" onClick={() => openGallery(item)}>
                                    <HistoryItem
                                        image1={item.image1}
                                        image2={item.image2}
                                        image3={item.image3}
                                        image4={item.image4}
                                    />
                                </div>
                            );
                            return rows;
                        }, []).map((row, index) => (
                            <div className="history-row" key={index}>
                                {row}
                            </div>
                        ))}
                    </div>
                )}

                {showGallery && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                            background: 'rgba(0, 0, 0, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => setShowGallery(false)} // Close gallery when overlay is clicked
                    >
                        <div onClick={(e) => e.stopPropagation()}> {/* Prevent click from closing the gallery */}
                            <ImageGallery
                                items={galleryImages}
                                showPlayButton={false} // Hides the play button
                                showFullscreenButton={false} // Hides the fullscreen button
                            />
                            <img src={iconCloseHistory} alt="close"

                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    zIndex: 1001 // Ensure the button is above the gallery images
                                }}
                                onClick={() => setShowGallery(false)}
                            />

                        </div>
                    </div>
                )}


            </div>

            <div className='bottom-sticky-bar history-left-desktop-margin'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: '100%', justifyContent: 'center' }}>
                    <Link to="/logged-in" className='sticky-bar-text'>
                        <img src={loggedSlider} alt="Slider" />
                    </Link>
                    <Link to="/logged-in" className='sticky-bar-text'>
                        <span >Discover</span>
                    </Link>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: '100%', justifyContent: 'center', gap: '1px' }}>
                    <div className='sticky-bar-text'>
                        <img src={loggedRefresh} alt="Refresh" />
                    </div>
                    <div className='sticky-bar-text'>
                        <span className='span-first-gradient' style={{ fontSize: '14px' }}>History</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default History;