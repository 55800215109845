import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import the Link component
import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/five-css.css';
import './css/three-css.css';
import { fetchUserData } from './utils';

function Saving() { // Component names should be capitalized
    const startTime = useRef(Date.now());
    const location = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        fetchUserData(() => { }, () => { }, () => { }, setUserId);
    }, []);

    const createUniqueFolderName = () => {

        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 90) + 10; // Generates a random number between 10 and 99
        const uniqueIdentifier = `${timestamp}${randomNumber}`;

        // const folderName = `C:\\Users\\Sasha\\Downloads\\${uniqueIdentifier}`;
        const folderName = `/var/www/www-root/data/www/headshotai.app/data/source/${uniqueIdentifier}`;

        localStorage.setItem('uniqueFolderName', folderName); // Save the unique folder name to local storage
        return folderName;
    };

    useEffect(() => {
        // Ensure that userId is set and discord_image_url is available in location state
        if (userId && location.state?.discord_image_url) {
            const outputFolder = createUniqueFolderName();
            // console.log('Output folder:', outputFolder); // Log the unique folder name

            // Call splitImage with the required parameters
            const fetchPromise = fetch(`${process.env.REACT_APP_SERVER_URL}/split-image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inputDiscImage: location.state.discord_image_url,
                    inputImage: localStorage.getItem('uploadedFilePath'),
                    // inputImage: 'C:\\Users\\Sasha\\Downloads\\1714570576668.png',
                    outputDir: outputFolder,
                    userId: userId
                }),
            });

            fetchPromise
                .then(response => {
                    // console.log('Split response WHAT?:', response);

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Parse the JSON from the response
                })
                .then(result => {
                    if (result.imageUrls && result.imageUrls.length > 0) {
                        navigate('/sixth', { state: { imageUrls: result.imageUrls } });
                    } else {
                        navigate('/third', { state: { fromFifth: true } });
                    }
                })
                .catch(error => {
                    console.error('Error calling split-image endpoint:', error);
                });
        }
    }, [userId, location.state, navigate]);

    const [progress, setProgress] = useState(60);

    useEffect(() => {
        const totalTime = 40000; // Total time for the timeout is 1 min

        const updateProgress = () => {
            const elapsedTime = Date.now() - startTime.current;
            const progressValue = 60 + ((elapsedTime / totalTime) * (100 - 60));
            setProgress(progressValue.toFixed(0)); // No decimal places

            if (elapsedTime < totalTime) {
                const randomInterval = 1000;//(Math.floor(Math.random() * 4) + 1) * 4000;
                // console.log(randomInterval); // Log the random interval
                setTimeout(updateProgress, randomInterval); // Schedule the next update
            } else {
                setProgress(100); // Ensure progress is set to 100 at the end
            }
        };

        // Start the initial progress update with a random interval
        updateProgress(); // Call updateProgress immediately to start the progress

        const timeoutId = setTimeout(() => {
            navigate('/third', { state: { fromFifth: true } });
        }, totalTime);

        // Clean up the timeout when the component unmounts
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        // Poll the /check-folder endpoint to see if the folder is ready
        const pollFolderReady = () => {
            fetch(`${process.env.REACT_APP_SERVER_URL}/check-folder?folder=${encodeURIComponent(localStorage.getItem('uniqueFolderName') + '-repl')}`)
                .then(response => {
                    if (response.ok) {
                        navigate('/sixth');
                    } //else {
                        // If the API does not return OK, log the status and keep polling
                       // console.log('Folder not ready yet, status:', response.status);
                    //}
                })
                .catch(error => {
                    console.error('Error polling check-folder endpoint:', error);
                });
        };
    
        // Start polling if userId is set and discord_image_url is available in location state
        if (userId && location.state?.discord_image_url) {
            const intervalId = setInterval(pollFolderReady, 1000); // Poll every 10 seconds
    
            // Clean up the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [userId, location.state, navigate]);

    return (
        <div className="App"> {/* Use the same className as your App component for consistency */}
            <header className="App-header-fifth">

                <span className='percent-wrapper'>{progress}%</span>
                <div>
                    <span className="fifth-message-one" style={{ color: '#111112' }}>Starting </span>
                    
                    <span className="fifth-message-one" style={{ color: '#111112' }}>.</span>
                </div>
                <span className='fifth-message-two' style={{ color: '#111112' }}>Done</span>
                <p className="saving" >Processing and saving <span>.</span><span>.</span><span>.</span></p>

            </header>
        </div>
    );
}

export default Saving;