import React, { useState, useEffect } from 'react';
import './css/App.css';
import imageV from './assets/images/imageV.jpg';
import imageB from './assets/images/imageB.jpg';
import imageL from './assets/images/imageL.jpg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Second from './second';
import Third from './third';
import Forth from "./forth";
import Fifth from "./fifth";
import Sixth from "./sixth";
import Seventh from "./seventh";
import Ninth from "./ninth";
import NotFound from './NotFound';
import Login from './login';
import LoggedIn from './logged-in';
import History from './history';
import Account from './account';
import OAuthRedirect from './oauth-redirect';
import AppleRedirect from './apple-redirect';
import Saving from './saving';
// images for preload
import image13 from './assets/images/second-image.png'

import image1female from './assets/images/16.png'
import image3female from './assets/images/17.png'
import image5male from './assets/images/19.png'
import image2female from './assets/images/15.png'
import image4male from './assets/images/18.png'
import image6female from './assets/images/20.png'

import image14 from './assets/images/brush.png'
import image15 from './assets/images/Frame 1285.png'
import image16 from './assets/images/grid-8.png'
import image17 from './assets/images/key.png'
import femaleSignUpDesktop from './assets/images/female-sign-up-desktop.png';
import loggedFemale from './assets/images/loggedFemale.png';
import loggedMale from './assets/images/loggedMale.png';
import loggedSlider from './assets/images/slider-horizontal.png';
import loggedRefresh from './assets/images/refresh-left-square.png';
import loggedSliderGray from './assets/images/slider-horizontal-gray.png';
import loggedRefreshActive from './assets/images/refresh-left-square-active.png';
import okImage from './assets/images/Frame 1642.png'; // Path to your new image 
import image1 from './assets/images/Frame 1643.png';
import image2 from './assets/images/Frame 1644.png';
import notOkImage from './assets/images/Frame 16421.png'; // Path to your new image 
import image3 from './assets/images/Frame 16431.png';
import image4 from './assets/images/Frame 16441.png';

const styleImages = {
  0: imageL,
  1: imageB,
  2: imageV,
};

function App() {

  const [isShortScreen, setIsShortScreen] = useState(window.innerHeight < 750);

  const [selectedStyleIndex, setSelectedStyleIndex] = useState(2);

  // Function to handle style selection
  const handleStyleSelect = (index) => {
    setSelectedStyleIndex(index);
  };


  const imageUrls = [
    image1female, image3female, image5male, image2female, image4male, image6female,
    okImage, image1, image2, notOkImage, image3, image4, loggedSlider, loggedRefresh,
    image13, image14, image15, image16, image17, loggedSliderGray, loggedRefreshActive,
    femaleSignUpDesktop, loggedFemale, loggedMale, imageL, imageB
  ];

  function preloadImages(urls) {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }

  useEffect(() => {
    preloadImages(imageUrls);

    const handleResize = () => {
      setIsShortScreen(window.innerHeight < 750);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const appStyle = {
    maxWidth: isShortScreen ? '70%' : '100%',
    marginTop: '20px'
  };

  // useEffect(() => {
  //   localStorage.setItem('selectedStyleIndex', selectedStyleIndex);
  //   console.log('selectedStyleIndex:', selectedStyleIndex);
  // }, [selectedStyleIndex]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <div className="container">

              <header className="App-header" >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  {/* <div className='image-first-wrapper'> */}
                  <img src={styleImages[selectedStyleIndex]} className="image-first" style={appStyle} alt="Style" />
                  {/* </div> */}
                  <div className='all-style-button'>
                    {['Luxe', 'Business', 'Vacation'].map((style, index) => (
                      <div
                        key={style}
                        className={`style-button ${selectedStyleIndex === index ? 'style-button-pressed' : ''}`}
                        onClick={() => handleStyleSelect(index)}
                      >
                        <span>{style}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='div-span-first' style={{ marginBottom: '10px' }}>
                  <div>
                    <span className='span-first-gradient' style={{ fontStyle: 'italic' }}>Discover </span>
                    <span className='span-first'>pictures</span>
                  </div>
                  <div>
                    <span className='span-first'>that fit your</span>
                    <span className='span-first' style={{ fontStyle: 'italic' }}> lifestyle</span>
                  </div>
                  <span className='span-first'>best!</span>
                </div>
              </header>

              <div className="bottom-button">

                <Link to="/second">
                  <button className="App-button">Continue</button>
                </Link>
              </div>
            </div>
          </div>
        } />
        <Route path="/second" element={<Second />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logged-in" element={<LoggedIn />} />
        <Route path="/history" element={<History />} />
        <Route path="/account" element={<Account />} />
        <Route path="/third" element={<Third />} />
        <Route path="/forth" element={<Forth />} />
        <Route path="/fifth" element={<Fifth />} />
        <Route path="/sixth" element={<Sixth />} />
        <Route path="/seventh" element={<Seventh />} />
        <Route path="/ninth" element={<Ninth />} />
        <Route path="/oauth-redirect" element={<OAuthRedirect />} />
        <Route path="/apple-redirect" element={<AppleRedirect />} />
        <Route path="/saving" element={<Saving />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;