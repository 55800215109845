import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import the Link component
import './css/App.css'; // Assuming you want to use the same CSS as your App component
import SignUp from './sign-up';

import image1female from './assets/images/16.png'
import image3female from './assets/images/17.png'
import image5male from './assets/images/19.png'
import image2female from './assets/images/15.png'
import image4male from './assets/images/18.png'
import image6female from './assets/images/20.png'
import { fetchUserDataLogin } from './utils';

function Second() { // Component names should be capitalized
  const desktopWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < desktopWidth);
    };

    const savedIndex = localStorage.getItem('selectedStyleIndex');
    // console.log('Passed value: ', savedIndex);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDataLogin(() => { }, () => { }, () => { }, setUserId);
  }, []);

  const handleContinue = () => {
    if (userId !== null && userId !== undefined) {
      navigate('/logged-in'); // Replace '/logged-in' with the actual route for the logged-in page
    } else {
      toggleDivVisibility();
    }
  };

  return (
    <div className="App">

      <div className={`container ${isDivVisible ? 'blur-effect' : ''}`}>
        <header className="App-header-two">

          <div className="image-area">
            <div className="column" style={{ marginBottom: '-50px' }}>
              <img src={image1female} alt="Female 1" />
              <img src={image2female} alt="Female 1" />
            </div>
            <div className="column">
              <img src={image3female} alt="Female 2" />
              <img src={image4male} alt="Male 2" />
            </div>
            <div className="column" style={{ marginBottom: '50px' }}>
              <img src={image5male} alt="Male 3" />
              <img src={image6female} alt="Female 3" />
            </div>
          </div>

          <div className='div-span-first'>
            <div>
              <span className='span-first-gradient' style={{ fontStyle: 'italic' }}>Enhance </span>
              <span className='span-first'>your photos by taking</span>
            </div>
            <div>
              <span className='span-first'>them to different</span>
              <span className='span-first' style={{ fontStyle: 'italic' }}> AI-generated </span>
            </div>
            <span className='span-first'>poses and styles</span>
          </div>

        </header>

        <div className="bottom-button">

          {isMobile ? (
            <>
              <Link to="/login">
                {/* <Link to="/login"> */}
                <button className="App-button">Continue</button>
              </Link>
            </>
          ) : (
            <>
              <button onClick={handleContinue} className={`App-button ${isDivVisible ? 'blur-effect' : ''}`}>Continue</button>
            </>
          )}
        </div>
      </div>
      {isDivVisible && (
        <div className="sign-up-div">
          <SignUp onToggle={toggleDivVisibility} />
        </div>
      )}
    </div>
  );
}

export default Second;