import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/App.css';
import './css/login.css';

import iconUser from './assets/images/user.png';
import loggedFemale from './assets/images/loggedFemale.png';
import loggedMale from './assets/images/loggedMale.png';
import loggedSlider from './assets/images/slider-horizontal.png';
import loggedRefresh from './assets/images/refresh-left-square.png';

import businessM1 from './assets/images/Business/m_airport.jpg';
import businessM2 from './assets/images/Business/m_business_centre.jpg';
import businessM3 from './assets/images/Business/m_library.jpg';
import businessM4 from './assets/images/Business/m_market_data.jpg';
import businessM5 from './assets/images/Business/m_coffee break.jpg';
import businessM6 from './assets/images/Business/m_brainstorming.jpg';
import businessM7 from './assets/images/Business/m_jet.jpg';
import businessF1 from './assets/images/Business/w_airport.jpg';
import businessF2 from './assets/images/Business/w_business_centre.jpg';
import businessF3 from './assets/images/Business/w_library.jpg';
import businessF4 from './assets/images/Business/w_market_data.jpg';
import businessF5 from './assets/images/Business/w_coffee break.jpg';
import businessF6 from './assets/images/Business/w_brainstorming.jpg';
import businessF7 from './assets/images/Business/w_jet.jpg';

import vacationM1 from './assets/images/Vacation/m_japan.jpg';
import vacationM2 from './assets/images/Vacation/m_turkey.jpg';
import vacationM3 from './assets/images/Vacation/m_france.jpg';
import vacationM4 from './assets/images/Vacation/m_dubai.jpg';
import vacationM5 from './assets/images/Vacation/m_italy.jpg';
import vacationM6 from './assets/images/Vacation/m_australia.jpg';
import vacationM7 from './assets/images/Vacation/m_brazil.jpg';
import vacationM8 from './assets/images/Vacation/m_egypt.jpg';
import vacationM9 from './assets/images/Vacation/m_spain.jpg';
import vacationF1 from './assets/images/Vacation/w_japan.jpg';
import vacationF2 from './assets/images/Vacation/w_turkey.jpg';
import vacationF3 from './assets/images/Vacation/w_france.jpg';
import vacationF4 from './assets/images/Vacation/w_dubai.jpg';
import vacationF5 from './assets/images/Vacation/w_italy.jpg';
import vacationF6 from './assets/images/Vacation/w_australia.jpg';
import vacationF7 from './assets/images/Vacation/w_brazil.jpg';
import vacationF8 from './assets/images/Vacation/w_egypt.jpg';
import vacationF9 from './assets/images/Vacation/w_spain.jpg';

import luxuryM1 from './assets/images/Luxury/m_luxury_car.jpg';
import luxuryM2 from './assets/images/Luxury/m_evening.jpg';
import luxuryM3 from './assets/images/Luxury/m_yacht.jpg';
import luxuryM4 from './assets/images/Luxury/m_penthouse.jpg';
import luxuryM5 from './assets/images/Luxury/m_fashion_week.jpg';
import luxuryM6 from './assets/images/Luxury/m_garden.jpg';
import luxuryM7 from './assets/images/Luxury/m_resort.jpg';
import luxuryM8 from './assets/images/Luxury/m_mansion.jpg';
import luxuryF1 from './assets/images/Luxury/w_luxury_car.jpg';
import luxuryF2 from './assets/images/Luxury/w_evening.jpg';
import luxuryF3 from './assets/images/Luxury/w_yacht.jpg';
import luxuryF4 from './assets/images/Luxury/w_penthouse.jpg';
import luxuryF5 from './assets/images/Luxury/w_fashion_week.jpg';
import luxuryF6 from './assets/images/Luxury/w_garden.jpg';
import luxuryF7 from './assets/images/Luxury/w_resort.jpg';
import luxuryF8 from './assets/images/Luxury/w_mansion.jpg';

import instaM1 from './assets/images/Insta/m_working_at_home.jpg';
import instaM2 from './assets/images/Insta/m_gym.jpg';
import instaM3 from './assets/images/Insta/m_streets.jpg';
import instaM4 from './assets/images/Insta/m_yoga.jpg';
import instaM5 from './assets/images/Insta/m_bookstore.jpg';
import instaM6 from './assets/images/Insta/m_cooking.jpg';
import instaM7 from './assets/images/Insta/m_autumn_bike.jpg';
import instaM8 from './assets/images/Insta/m_painting.jpg';
import instaF1 from './assets/images/Insta/w_working_at_home.jpg';
import instaF2 from './assets/images/Insta/w_gym.jpg';
import instaF3 from './assets/images/Insta/w_streets.jpg';
import instaF4 from './assets/images/Insta/w_yoga.jpg';
import instaF5 from './assets/images/Insta/w_bookstore.jpg';
import instaF6 from './assets/images/Insta/w_cooking.jpg';
import instaF7 from './assets/images/Insta/w_autumn_bike.jpg';
import instaF8 from './assets/images/Insta/w_painting.jpg';

import linkedinM1 from './assets/images/LinkedIn/m_white_background.jpg';
import linkedinM2 from './assets/images/LinkedIn/m_loft.jpg';
import linkedinM3 from './assets/images/LinkedIn/m_office.jpg';
import linkedinM4 from './assets/images/LinkedIn/m_marble.jpg';
import linkedinM5 from './assets/images/LinkedIn/m_business_centre.jpg';
import linkedinF1 from './assets/images/LinkedIn/w_white_background.jpg';
import linkedinF2 from './assets/images/LinkedIn/w_loft.jpg';
import linkedinF3 from './assets/images/LinkedIn/w_office.jpg';
import linkedinF4 from './assets/images/LinkedIn/w_marble.jpg';
import linkedinF5 from './assets/images/LinkedIn/w_business_centre.jpg';

import { fetchUserData } from './utils';

function LoggedIn() {
    const [selectedGender, setSelectedGender] = useState(localStorage.getItem('selectedGender') || 'woman'); // Added state to track selected gender
    const [selectedImage, setSelectedImage] = useState(localStorage.getItem('selectedImage') || 'businessF1'); // Replace 'defaultImageId' with your actual default image ID
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        fetchUserData(() => { }, () => { }, () => { }, setUserId);
    }, []);

    const handleGenderClick = (gender) => { // Added function to handle gender selection
        setSelectedGender(gender);
        if (gender === 'man') {
            setSelectedImage('businessM1');
        } else if (gender === 'woman') {
            setSelectedImage('businessF1');
        }
    };

    const navigate = useNavigate();

    const handleImageClick = (imageId) => {
        setSelectedImage(imageId);
        localStorage.setItem('selectedImage', imageId);
        // console.log('selectedImage', imageId);
        // console.log('selectedGender', selectedGender);
        // Navigate to the /third route after setting the image
        navigate('/third');
    };

    // Add this function to check if the image is selected
    const isSelected = (imageId) => {
        return selectedImage === imageId;
    };

    useEffect(() => {
        localStorage.setItem('selectedImage', selectedImage);
        localStorage.setItem('selectedGender', selectedGender);

        // console.log('selectedImage', selectedImage);
        // console.log('selectedGender', selectedGender);
    }, [selectedImage, selectedGender]);

    return (
        <div className="App" style={{ justifyContent: 'flex-start', height: '100%' }}>
            <>
            <div className='div-top-bottom'>

                <div style={{ width: "100%", display: "flex", justifyContent: 'end', marginTop: "20px", marginBottom: "15px" }}>
                    <Link to="/account" style={{ "width": "auto" }}>
                        <div className='top-icons'>
                            <img src={iconUser} alt="close icon" style={{ opacity: '0.65' }} />
                        </div>
                    </Link>
                </div>

            </div>

            <div className='logged-image' style={{ display: selectedGender === 'woman' ? 'block' : 'none' }}>
                <img src={loggedFemale} alt="Logged Female" />
            </div>
            <div className='logged-image' style={{ display: selectedGender === 'man' ? 'block' : 'none' }}>
                <img src={loggedMale} alt="Logged Male" />
            </div>

            <div className='clecked-div-wrapper'>
                <div className={`change-logged-image ${selectedGender === 'man' ? 'change-logged-image-clicked' : ''}`} onClick={() => handleGenderClick('man')}><span>Man</span></div>
                <div className={`change-logged-image ${selectedGender === 'woman' ? 'change-logged-image-clicked' : ''}`} onClick={() => handleGenderClick('woman')}><span>Woman</span></div>
            </div>
            <div className='logged-container'>
                <div className='category-wrapper' style={{ display: selectedGender === 'man' ? '' : 'none' }}>
                    <p>💼 Business</p>
                    <div className='category-img'>
                        <img
                            src={businessM1}
                            alt="business Male"
                            id="businessM1" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM1')}
                            className={isSelected('businessM1') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM2}
                            alt="business Male"
                            id="businessM2" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM2')}
                            className={isSelected('businessM2') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM3}
                            alt="business Male"
                            id="businessM3" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM3')}
                            className={isSelected('businessM3') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM4}
                            alt="business Male"
                            id="businessM4" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM4')}
                            className={isSelected('businessM4') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM5}
                            alt="business Male"
                            id="businessM5" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM5')}
                            className={isSelected('businessM5') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM6}
                            alt="business Male"
                            id="businessM6" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM6')}
                            className={isSelected('businessM6') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessM7}
                            alt="business Male"
                            id="businessM7" // Assign an ID to the image
                            onClick={() => handleImageClick('businessM7')}
                            className={isSelected('businessM7') ? 'img-selected' : ''}
                        />

                    </div>
                    <p>🌴 Vacation</p>
                    <div className='category-img'>
                        <img
                            src={vacationM1}
                            alt="vacation Male"
                            id="vacationM1" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM1')}
                            className={isSelected('vacationM1') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM2}
                            alt="vacation Male"
                            id="vacationM2" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM2')}
                            className={isSelected('vacationM2') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM3}
                            alt="vacation Male"
                            id="vacationM3" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM3')}
                            className={isSelected('vacationM3') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM4}
                            alt="vacation Male"
                            id="vacationM4" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM4')}
                            className={isSelected('vacationM4') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM5}
                            alt="vacation Male"
                            id="vacationM5" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM5')}
                            className={isSelected('vacationM5') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM6}
                            alt="vacation Male"
                            id="vacationM6" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM6')}
                            className={isSelected('vacationM6') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM7}
                            alt="vacation Male"
                            id="vacationM7" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM7')}
                            className={isSelected('vacationM7') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM8}
                            alt="vacation Male"
                            id="vacationM8" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM8')}
                            className={isSelected('vacationM8') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationM9}
                            alt="vacation Male"
                            id="vacationM9" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationM9')}
                            className={isSelected('vacationM9') ? 'img-selected' : ''}
                        />
                    </div>
                    <p>👑 Luxury</p>
                    <div className='category-img'>
                        <img
                            src={luxuryM1}
                            alt="luxury Male"
                            id="luxuryM1" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM1')}
                            className={isSelected('luxuryM1') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM2}
                            alt="luxury Male"
                            id="luxuryM2" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM2')}
                            className={isSelected('luxuryM2') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM3}
                            alt="luxury Male"
                            id="luxuryM3" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM3')}
                            className={isSelected('luxuryM3') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM4}
                            alt="luxury Male"
                            id="luxuryM4" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM4')}
                            className={isSelected('luxuryM4') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM5}
                            alt="luxury Male"
                            id="luxuryM5" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM5')}
                            className={isSelected('luxuryM5') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM6}
                            alt="luxury Male"
                            id="luxuryM6" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM6')}
                            className={isSelected('luxuryM6') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM7}
                            alt="luxury Male"
                            id="luxuryM7" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM7')}
                            className={isSelected('luxuryM7') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryM8}
                            alt="luxury Male"
                            id="luxuryM8" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryM8')}
                            className={isSelected('luxuryM8') ? 'img-selected' : ''}
                        />
                    </div>
                    <p>📷 Insta</p>
                    <div className='category-img'>
                        <img
                            src={instaM1}
                            alt="insta Male"
                            id="instaM1" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM1')}
                            className={isSelected('instaM1') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM2}
                            alt="insta Male"
                            id="instaM2" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM2')}
                            className={isSelected('instaM2') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM3}
                            alt="insta Male"
                            id="instaM3" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM3')}
                            className={isSelected('instaM3') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM4}
                            alt="insta Male"
                            id="instaM4" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM4')}
                            className={isSelected('instaM4') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM5}
                            alt="insta Male"
                            id="instaM5" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM5')}
                            className={isSelected('instaM5') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM6}
                            alt="insta Male"
                            id="instaM6" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM6')}
                            className={isSelected('instaM6') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM7}
                            alt="insta Male"
                            id="instaM7" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM7')}
                            className={isSelected('instaM7') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaM8}
                            alt="insta Male"
                            id="instaM8" // Assign an ID to the image
                            onClick={() => handleImageClick('instaM8')}
                            className={isSelected('instaM8') ? 'img-selected' : ''}
                        />

                    </div>
                    <p>🖋 LinkedIn</p>
                    <div className='category-img'>
                        <img
                            src={linkedinM1}
                            alt="linkedin Male"
                            id="linkedinM1" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinM1')}
                            className={isSelected('linkedinM1') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinM2}
                            alt="linkedin Male"
                            id="linkedinM2" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinM2')}
                            className={isSelected('linkedinM2') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinM3}
                            alt="linkedin Male"
                            id="linkedinM3" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinM3')}
                            className={isSelected('linkedinM3') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinM4}
                            alt="linkedin Male"
                            id="linkedinM4" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinM4')}
                            className={isSelected('linkedinM4') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinM5}
                            alt="linkedin Male"
                            id="linkedinM5" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinM5')}
                            className={isSelected('linkedinM5') ? 'img-selected' : ''}
                        />
                    </div>
                </div>

                <div className='category-wrapper' style={{ display: selectedGender === 'woman' ? '' : 'none' }}>
                    <p>💼 Business</p>
                    <div className='category-img'>
                        <img
                            src={businessF1}
                            alt="business female"
                            id="businessF1" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF1')}
                            className={isSelected('businessF1') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF2}
                            alt="business female"
                            id="businessF2" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF2')}
                            className={isSelected('businessF2') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF3}
                            alt="business female"
                            id="businessF3" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF3')}
                            className={isSelected('businessF3') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF4}
                            alt="business female"
                            id="businessF4" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF4')}
                            className={isSelected('businessF4') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF5}
                            alt="business female"
                            id="businessF5" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF5')}
                            className={isSelected('businessF5') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF6}
                            alt="business female"
                            id="businessF6" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF6')}
                            className={isSelected('businessF6') ? 'img-selected' : ''}
                        />
                        <img
                            src={businessF7}
                            alt="business female"
                            id="businessF7" // Assign an ID to the image
                            onClick={() => handleImageClick('businessF7')}
                            className={isSelected('businessF7') ? 'img-selected' : ''}
                        />

                    </div>
                    <p>🌴 Vacation</p>
                    <div className='category-img'>
                        <img
                            src={vacationF1}
                            alt="vacation female"
                            id="vacationF1" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF1')}
                            className={isSelected('vacationF1') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF2}
                            alt="vacation female"
                            id="vacationF2" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF2')}
                            className={isSelected('vacationF2') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF3}
                            alt="vacation female"
                            id="vacationF3" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF3')}
                            className={isSelected('vacationF3') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF4}
                            alt="vacation female"
                            id="vacationF4" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF4')}
                            className={isSelected('vacationF4') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF5}
                            alt="vacation female"
                            id="vacationF5" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF5')}
                            className={isSelected('vacationF5') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF6}
                            alt="vacation female"
                            id="vacationF6" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF6')}
                            className={isSelected('vacationF6') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF7}
                            alt="vacation female"
                            id="vacationF7" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF7')}
                            className={isSelected('vacationF7') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF8}
                            alt="vacation female"
                            id="vacationF8" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF8')}
                            className={isSelected('vacationF8') ? 'img-selected' : ''}
                        />
                        <img
                            src={vacationF9}
                            alt="vacation female"
                            id="vacationF9" // Assign an ID to the image
                            onClick={() => handleImageClick('vacationF9')}
                            className={isSelected('vacationF9') ? 'img-selected' : ''}
                        />

                    </div>
                    <p>👑 Luxury</p>
                    <div className='category-img'>
                        <img
                            src={luxuryF1}
                            alt="luxury female"
                            id="luxuryF1" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF1')}
                            className={isSelected('luxuryF1') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF2}
                            alt="luxury female"
                            id="luxuryF2" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF2')}
                            className={isSelected('luxuryF2') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF3}
                            alt="luxury female"
                            id="luxuryF3" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF3')}
                            className={isSelected('luxuryF3') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF4}
                            alt="luxury female"
                            id="luxuryF4" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF4')}
                            className={isSelected('luxuryF4') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF5}
                            alt="luxury female"
                            id="luxuryF5" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF5')}
                            className={isSelected('luxuryF5') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF6}
                            alt="luxury female"
                            id="luxuryF6" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF6')}
                            className={isSelected('luxuryF6') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF7}
                            alt="luxury female"
                            id="luxuryF7" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF7')}
                            className={isSelected('luxuryF7') ? 'img-selected' : ''}
                        />
                        <img
                            src={luxuryF8}
                            alt="luxury female"
                            id="luxuryF8" // Assign an ID to the image
                            onClick={() => handleImageClick('luxuryF8')}
                            className={isSelected('luxuryF8') ? 'img-selected' : ''}
                        />
                    </div>
                    <p>📷 Insta</p>
                    <div className='category-img'>
                        <img
                            src={instaF1}
                            alt="insta female"
                            id="instaF1" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF1')}
                            className={isSelected('instaF1') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF2}
                            alt="insta female"
                            id="instaF2" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF2')}
                            className={isSelected('instaF2') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF3}
                            alt="insta female"
                            id="instaF3" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF3')}
                            className={isSelected('instaF3') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF4}
                            alt="insta female"
                            id="instaF4" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF4')}
                            className={isSelected('instaF4') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF5}
                            alt="insta female"
                            id="instaF5" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF5')}
                            className={isSelected('instaF5') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF6}
                            alt="insta female"
                            id="instaF6" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF6')}
                            className={isSelected('instaF6') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF7}
                            alt="insta female"
                            id="instaF7" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF7')}
                            className={isSelected('instaF7') ? 'img-selected' : ''}
                        />
                        <img
                            src={instaF8}
                            alt="insta female"
                            id="instaF8" // Assign an ID to the image
                            onClick={() => handleImageClick('instaF8')}
                            className={isSelected('instaF8') ? 'img-selected' : ''}
                        />
                    </div>
                    <p>🖋 LinkedIn</p>
                    <div className='category-img'>
                        <img
                            src={linkedinF1}
                            alt="linkedin female"
                            id="linkedinF1" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinF1')}
                            className={isSelected('linkedinF1') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinF2}
                            alt="linkedin female"
                            id="linkedinF2" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinF2')}
                            className={isSelected('linkedinF2') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinF3}
                            alt="linkedin female"
                            id="linkedinF3" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinF3')}
                            className={isSelected('linkedinF3') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinF4}
                            alt="linkedin female"
                            id="linkedinF4" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinF4')}
                            className={isSelected('linkedinF4') ? 'img-selected' : ''}
                        />
                        <img
                            src={linkedinF5}
                            alt="linkedin female"
                            id="linkedinF5" // Assign an ID to the image
                            onClick={() => handleImageClick('linkedinF5')}
                            className={isSelected('linkedinF5') ? 'img-selected' : ''}
                        />
                    </div>
                </div>
            </div>

            <div className='bottom-sticky-bar'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: '100%', justifyContent: 'center' }}>
                    <div className='sticky-bar-text'>
                        <img src={loggedSlider} alt="Logged Male" />
                    </div>
                    <div className='sticky-bar-text'>
                        <span className='span-first-gradient' style={{ fontSize: '14px' }}>Discover</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', height: '100%', justifyContent: 'center', gap: '1px' }}>
                    <Link to="/history" className='sticky-bar-text'>
                        <img src={loggedRefresh} alt="Logged Male" />
                    </Link>
                    <Link to="/history" className='sticky-bar-text'>
                        <span >History</span>
                    </Link>
                </div>
            </div>
            </>
        </div>
    );
}

export default LoggedIn;