import React from 'react';
import './css/App.css';
import './css/six-css.css';

function ResultItem({ image1, image2, image3, image4, onClick }) {
    const images = [image1, image2, image3, image4];

    const handleImageError = (e) => {
        e.target.style.display = 'none'; // Hide the image element if the image cannot be loaded
    };

    return (
        <div className="result-item-deck" onClick={onClick}>
            {images.map((url, index) =>
                url ? <img key={index} src={url} alt={`Image ${index + 1}`} onError={handleImageError} /> : null
            )}
        </div>
    );
}

export default ResultItem;